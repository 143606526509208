<template>
    <section>
        <jetton-wallet v-if="isJettonWallet"
            v-bind:address="address"
            v-bind="jettonProps"/>

        <jetton-info v-else
            v-bind:address="address"
            v-bind="jettonProps"
            v-bind:imageTA="imageTA"/>
    </section>
</template>

<script>
import { getAccountInfo, getJettonData, getJettonInfo_tonapi } from '~/api/tonapi.js';
import { truncateTicker } from '~/helpers';
import { getJettonInfo } from '../../api/toncenterV3.js';
import { getJetton } from '../../api/jettoncat.js';
import JettonWallet from './JettonWallet.vue';
import JettonInfo from './JettonInfo.vue';

export default {
    props: {
        address: {
            type: String,
            required: true,
        },
        skeletonHint: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            isJettonWallet: false,
            jettonProps: {},
            isLoading: false,
            type: undefined,
            props: undefined,
            activeTab: 'transactions',
            imageTA: null,
        };
    },

    watch: {
        address() {
            this.checkAndRedirect().then(() => {
                this.jettonProps = {};
                this.loadData();
            });
        },
    },

    created() {
        this.checkAndRedirect().then(() => {
            this.loadData();
        });
    },

    methods: {
        reset() {
            this.isJettonWallet = false;
            this.jettonProps = {};
        },

        async checkAndRedirect() {
            try {
                const response = await getAccountInfo(this.address);
                const type = response?.interfaces[0];

                if (type === 'jetton_wallet' || type === 'jetton_wallet_v1' || type === 'jetton_wallet_governed') {
                    this.$router.push(this.$localizeRoute({
                        name: 'jetton-wallet',
                        params: { address: this.address },
                    }));
                }
            } catch (error) {
                console.error(error);
            }
        },

        async loadData() {
            this.reset();
            this.isLoading = true;

            try {
                await this.loadMainData();
                await this.loadDiamond();
            } catch (error) {
                console.error('Error loading data:', error);
            } finally {
                this.isLoading = false;
            }
        },

        async loadMainData() {
            try {
                const tonapi = await getJettonInfo_tonapi(this.address);
                this.imageTA = tonapi.metadata.image;

                const [resultAddress, resultJetton, resultJettonInfo] = await Promise.all([
                    getAccountInfo(this.address),
                    getJettonData(this.address),
                    getJettonInfo(this.address),
                ]);

                const { balance } = resultAddress;
                const { total_supply, metadata, admin, holders_count } = resultJetton;
                const { jetton_masters } = resultJettonInfo;

                this.isJettonWallet = this.skeletonHint === 'jetton_wallet';
                this.jettonProps = {
                    balance: Number(balance),
                    total_supply: Number(total_supply),
                    image_data: metadata.image,
                    name: truncateTicker(metadata.name, 26),
                    symbol: truncateTicker(metadata.symbol),
                    decimals: Number(metadata.decimals),
                    description: metadata.description,
                    admin_address: admin ? admin.address : null,
                    holders: holders_count,
                    metadata_url: jetton_masters[0]?.jetton_content.uri || null,
                };

                const payload = {
                    address: this.address,
                    name: metadata.name || metadata.symbol,
                    image: metadata.image,
                    type: 'jetton',
                };
                this.$store.dispatch('presaveAddressbookItem', payload);
            } catch (error) {
                console.error('Error loading data:', error);
            } finally {
                this.isLoading = false;
            }
        },

        async loadDiamond() {
            try {
                const resultJettonVolume = await getJetton(this.address);
                const { volume24h } = resultJettonVolume.data.jetton;

                this.jettonProps = {
                    ...this.jettonProps,
                    volume24h: Number(volume24h),
                };
            } catch (error) {
                this.jettonProps = {
                    ...this.jettonProps,
                    volume: 0,
                };
                console.error('Error loading market data:', error);
            }
        },
    },

    components: {
        JettonWallet,
        JettonInfo,
    },
};

</script>
