<template>
    <tr>
        <td class="jetton-list-link">
            <ui-link v-bind:to="{ name: 'jetton', params: { address: wallet.jetton_address }}">
                <ui-round-image class="jetton-list__icon" size="tiny" v-bind:src="wallet.jetton_meta.image.w72"/>
                <span style="font-weight: 500">{{ truncateTicker(wallet.jetton_meta.name, 26) || wallet.jetton_address }}</span>
            </ui-link>
            <div class="muted jetton-list-ticker">{{ truncateTicker(wallet.jetton_meta.symbol) }}</div>
        </td>
        <td style="max-width: 300px;">
            <b>{{ $ton(wallet.balance, wallet.jetton_meta.decimals) }}</b>
            <span style="margin-left: 2px;">{{ truncateTicker(wallet.jetton_meta.symbol) }}</span>
            <span style="margin-left: 2px;" class="muted">
                &asymp;
                <ui-format-price v-bind:price="wallet.usd_price" v-bind:symbol="'$'"/>
            </span>
        </td>
        <td class="ui-table__cell--align-right">
            <ui-address v-bind:address="wallet.address"/>
        </td>
    </tr>
</template>

<script>
import { truncateTicker } from '~/helpers.js';

export default {
    props: {
        wallet: Object,
    },

    methods: {
        truncateTicker,
    },
};
</script>

<style lang="scss">
.price-token-table {
    font-weight: 500;
}
.jetton-list-link a {
    display: inline-flex;
    align-items: center;
}
.jetton-list-ticker {
    margin-left: 6px;
}
</style>
