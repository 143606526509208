<template>
    <tr>
        <td class="tx-mobile-flex-content">
            <ui-link v-bind:to="{ name: 'jetton', params: { address: wallet.jetton_address }}" class="tx-link-jettons-account">
                <div class="tx-token-cell-icon">
                    <img v-bind:src="wallet.jetton_meta.image.w72" />
                </div>
                <div class="tx-mobile-content">
                    <div class="tx-mobile-content__top">
                        <div class="tx-mobile-content-row">
                            <span class="tx-mobile-content-jetton-name">{{ wallet.jetton_meta.name }}</span>
                            <span>
                                {{ $ton(wallet.balance, wallet.jetton_meta.decimals) }}
                                <span class="muted">  {{ truncateTicker(wallet.jetton_meta.symbol) }}</span>
                            </span>
                            <!-- <ui-address v-bind:address="wallet.address"/> -->
                        </div>
                        <div class="tx-mobile-content-row">
                            <span class="muted" style="margin-bottom: 10px;"><ui-format-price v-bind:price="wallet.jetton_usd_price" v-bind:symbol="'$'"/></span>
                            <span class="muted" style="margin-bottom: 10px;">
                                <ui-format-price v-bind:price="wallet.usd_price" v-bind:symbol="'$'"/>
                            </span>
                        </div>
                    </div>
                </div>
            </ui-link>
        </td>
    </tr>
</template>

<script>
import { truncateTicker } from '~/helpers.js';

export default {
    props: {
        wallet: Object,
        rates: Array,
    },

    methods: {
        truncateTicker,
    },
};
</script>

<style>
.tx-token-cell-icon {
    margin-top: 1em !important;
    margin-left: 1em !important;
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    display: flex;
    align-items: center;
    align-self: flex-start;
    justify-content: center;
    border-radius: 32px !important;
    overflow: hidden;
}

.tx-token-cell-icon img {
    width: 100%;
    height: 100%;
}

.tx-mobile-content-jetton-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 20px;
}

.tx-link-jettons-account {
    color: var(--body-text-color);
    text-decoration: none!important;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100% !important;
    line-height: 1.45;
}
</style>
