<template>
    <div ref="chartContainer" style="display: flex; flex-direction: column; position: relative; width: 100%; height: 100%;">
        <ui-chart-legend
            v-if="!hideLegend"
            v-bind:datasets="datasets"
            v-bind:colors="colors"
            v-bind:visibleSeries="visibleSeries"
            v-on:toggle-chart="handleToggleChart"
        />
        <div ref="chart" style="width: 100%; height: 100%;"></div>
        <ui-chart-tooltip
            ref="tooltip"
            v-bind:tooltipHeader="tooltipData.header"
            v-bind:tooltipItems="tooltipData.items"
            v-bind:position="tooltipData.position"
            v-bind:isVisible="tooltipData.isVisible"
        />
    </div>
</template>

<script>
import { createChart } from 'lightweight-charts';
import { formattedPriceChart } from '~/helpers';
import UiChartTooltip from './UiChartTooltip.vue';
import UiChartLegend from './UiChartLegend.vue';

export default {
    props: {
        labels: {
            type: Array,
            default: () => [],
        },
        datasets: {
            type: Array,
            default: () => [{
                data: [],
                label: '',
                suffix: ' ',
            }],
        },
        hideLegend: Boolean,
    },

    data() {
        return {
            chart: null,
            areaSeries: null,
            visibleSeries: [true, true],
            colors: ['rgb(108, 142, 117)', 'rgba(108, 142, 117, 0.314)'],
            tooltipData: {
                header: '',
                items: [],
                position: { x: 0, y: 0 },
                isVisible: false,
            },
        };
    },

    computed: {
        appTheme() {
            return this.$store.state.appTheme;
        },
    },

    mounted() {
        this.createSkeletonChart();
        this.createChart();
        this.updateChartSize();
        window.addEventListener('resize', this.handleResize);
    },

    watch: {
        datasets() {
            this.createChart();
            this.updateChartSize();
        },
        appTheme() {
            this.recreateChart();
        },
    },

    beforeDestroy() {
        if (this.chart) {
            this.chart.remove();
        }
        window.removeEventListener('resize', this.handleResize);
    },

    methods: {
        handleResize() {
            this.recreateChart();
        },

        createSkeletonChart() {
            if (this.chart) {
                this.chart.remove();
            }

            const skeletonColor = getComputedStyle(this.$refs.chart).getPropertyValue('--chart-skeleton-color').trim();

            this.chart = createChart(this.$refs.chart, {
                width: this.$refs.chart.clientWidth,
                height: this.$refs.chart.clientHeight,
                layout: {
                    background: { type: 'solid', color: 'transparent' },
                    textColor: skeletonColor,
                },
                crosshair: {
                    mode: 0,
                    vertLine: { visible: false },
                    horzLine: { visible: false },
                },
                timeScale: {
                    borderVisible: false,
                    minimumHeight: 50,
                    visible: false,
                },
                rightPriceScale: {
                    borderVisible: false,
                    visible: false,
                },
                grid: {
                    vertLines: { color: 'transparent' },
                    horzLines: { color: 'transparent' },
                    visible: false,
                },
                handleScroll: {
                    vertTouchDrag: false,
                    mouseWheel: false,
                    pressedMouseMove: false,
                },
                handleScale: {
                    axisPressedMouseMove: false,
                    mouseWheel: false,
                    pinch: false,
                },
            });

            this.areaSeries = this.chart.addHistogramSeries({
                color: skeletonColor,
                bottomColor: 'transparent',
                lineWidth: 2,
            });

            const now = Date.now();
            const skeletonLabels = Array.from({ length: 20 }, (_, i) => now - i * 60 * 1000).reverse();
            const skeletonDataset = Array.from({ length: 20 }, () => Math.random() * 20 + 10);

            const skeletonData = skeletonLabels.map((label, index) => ({
                time: Math.floor(label / 1000),
                value: skeletonDataset[index],
            }));

            this.areaSeries.setData(skeletonData);
            this.chart.timeScale().fitContent();
        },

        createChart() {
            if (!this.labels.length || !this.datasets.length) return;

            if (this.chart) {
                this.chart.remove();
            }

            const chartStyles = getComputedStyle(this.$refs.chart);
            const chartColor = chartStyles.getPropertyValue('--chart-grid-color').trim();
            const chartPointColor = chartStyles.getPropertyValue('--chart-point-color').trim();
            const opacityColor = chartStyles.getPropertyValue('--chart-line-green-opacity-color').trim();
            const mutedColor = chartStyles.getPropertyValue('--chart-line-muted-color').trim();

            this.chart = createChart(this.$refs.chart, {
                width: this.$refs.chart.clientWidth,
                height: this.$refs.chart.clientHeight,
                layout: {
                    background: {
                        type: 'solid',
                        color: 'transparent',
                    },
                    textColor: chartPointColor,
                },
                crosshair: {
                    horzLine: {
                        labelVisible: false,
                        visible: false,
                        color: mutedColor,
                    },
                    vertLine: {
                        labelVisible: false,
                        color: mutedColor,
                    },
                },
                timeScale: {
                    borderVisible: false,
                    minimumHeight: 30,
                    tickMarkMaxCharacterLength: 13,
                    allowBoldLabels: false,
                    tickMarkFormatter: this.formatTickMarks,
                    fixLeftEdge: true,
                    fixRightEdge: true,
                },
                rightPriceScale: {
                    borderVisible: false,
                    // autoScale: false,
                    scaleMargins: {
                        top: 0.2,
                        bottom: 0.2,
                    },
                },
                grid: {
                    vertLines: {
                        style: 3,
                        visible: true,
                        color: chartColor,
                    },
                    horzLines: {
                        visible: false,
                        color: chartColor,
                    },
                },
                handleScroll: {
                    vertTouchDrag: false,
                    mouseWheel: false,
                    pressedMouseMove: false,
                },
                handleScale: {
                    axisPressedMouseMove: false,
                    mouseWheel: false,
                    pinch: false,
                },
            });

            const histogramData = this.labels.map((label, index) => ({
                time: Math.floor(label / 1000),
                value: this.datasets[1]?.data[index],
            })).sort((a, b) => a.time - b.time);

            this.histogramSeries = this.chart.addHistogramSeries({
                color: opacityColor,
                priceScaleId: 'histogram',
                lastValueVisible: false,
                priceLineVisible: false,
            });

            this.histogramSeries.setData(histogramData);

            this.areaSeries = this.chart.addHistogramSeries({
                color: opacityColor,
                lineWidth: 2,
                priceScaleId: 'right',
                autoscaleInfoProvider: (original) => {
                    const res = original();
                    if (res !== null) {
                        res.priceRange.minValue += 3000000;
                    }
                    return res;
                },
                priceFormat: {
                    type: 'custom',
                    formatter: this.formatLargeNumber,
                },
            });

            const data = this.labels.map((label, index) => ({
                time: Math.floor(label / 1000),
                value: this.datasets[0]?.data[index],
            })).sort((a, b) => a.time - b.time);

            this.areaSeries.setData(data);

            this.chart.timeScale().fitContent();

            this.chart.subscribeCrosshairMove(this.updateTooltip);
        },

        handleToggleChart(index) {
            this.$set(this.visibleSeries, index, !this.visibleSeries[index]);
            if (index === 0 && this.areaSeries) {
                this.areaSeries.applyOptions({ visible: this.visibleSeries[index] });
            } else if (index === 1 && this.histogramSeries) {
                this.histogramSeries.applyOptions({ visible: this.visibleSeries[index] });
            }
        },

        formatTickMarks(time) {
            const date = new Date(time * 1000);

            // Show only XX:00 or XX:30 for minutes
            const minutes = date.getMinutes();

            const roundedMinutes = minutes < 30 ? 0 : 30;

            date.setMinutes(roundedMinutes, 0, 0);

            if (this.interval !== 1) {
                return date.toLocaleDateString(this.$i18n.locale, {
                    month: 'short',
                    day: 'numeric',
                });
            }
            return date.toLocaleTimeString(this.$i18n.locale, {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
            });
        },

        formatLargeNumber(value) {
            if (Math.abs(value) >= 1.0e9) return `${Math.round(value / 1.0e9)}B`;
            if (Math.abs(value) >= 1.0e6) return `${Math.round(value / 1.0e6)}M`;
            if (Math.abs(value) >= 1.0e3) return `${(value / 1.0e3).toFixed(2)}K`;
            return value === 0 ? '0' : value.toFixed(2);
        },

        recreateChart() {
            this.createSkeletonChart();
            this.createChart();
            this.updateChartSize();
        },

        updateChartSize() {
            if (this.chart) {
                this.chart.resize(this.$refs.chart.clientWidth, this.$refs.chart.clientHeight);
            }
        },

        updateTooltip(param) {
            if (param.time) {
                const date = new Date(param.time * 1000);
                this.tooltipData.header = `${date.toLocaleDateString(this.$i18n.locale, { day: 'numeric', month: 'long' })}, ${date.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}`;

                const containerRect = this.$refs.chartContainer.getBoundingClientRect();
                const { offsetWidth: tooltipWidth, offsetHeight: tooltipHeight } = this.$refs.tooltip.$el;
                const offsetX = 30;
                const offsetY = 30;

                let x = param.point.x + offsetX;
                let y = param.point.y + offsetY;

                if (x + tooltipWidth > containerRect.width) {
                    x = param.point.x - tooltipWidth - offsetX;
                }

                if (y + tooltipHeight > containerRect.height) {
                    y = param.point.y - tooltipHeight - offsetY;
                }

                if (x < 0) {
                    x = 0;
                }

                if (y < 0) {
                    y = 0;
                }

                this.tooltipData.position = { x, y };
                this.tooltipData.isVisible = true;

                const chartData = param.seriesData.get(this.areaSeries);
                const histogramData = param.seriesData.get(this.histogramSeries);

                // const formatValue = (value) => {
                //     if (value === null || value === undefined) return '0';

                //     const numericValue = parseFloat(value).toFixed(3);
                //     const [whole, fraction] = numericValue.split('.');
                //     const formattedWhole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

                //     return fraction && fraction !== '000' ? `${formattedWhole}.${fraction}` : formattedWhole;
                // };

                this.tooltipData.items = this.datasets.map((dataset, index) => ({
                    label: dataset.label,
                    value: formattedPriceChart(index === 0 ? chartData?.value : histogramData?.value),
                    suffix: dataset.suffix,
                }));
            } else {
                this.tooltipData.isVisible = false;
            }
        },
    },

    components: {
        UiChartTooltip, UiChartLegend,
    },
};
</script>
